<template>
  <div class="credits-exchange" v-loading="loading">
    <div class="r-title">积分兑换中心</div>
    <el-row class="r-account">
      <el-col :span="12">
        <i class="fa fa-user-circle-o r-icon mr5"></i>
        <span>账号：
          <span class="accout">{{ companyAmountList.user_nickname }}</span>
        </span>
      </el-col>
      <el-col :span="12" style="text-align: right">
        可用积分：
        <span class="c-orange">{{ companyAmountList.score }}</span>
        积分
      </el-col>
    </el-row>
    <el-form :model="creditsFormData" :rules="creditsRules" ref="creditsRef" label-width="120px">
      <el-form-item label="积分兑换项目：" prop="rechargeValue">
        <el-radio-group v-model="creditsFormData.rechargeValue" class="credits-radio">
          <el-radio :label="'' + 100" border size="medium">
            <ul class="r-project">
              <li class="r-integrate">
                100积分
              </li>
              <!-- <li class="r-present">(赠送100积分)</li> -->
              <!-- <li class="r-money">￥ 100</li> -->
            </ul>
          </el-radio>
          <el-radio :label="'' + 300" border size="medium">
            <ul class="r-project">
              <li class="r-integrate">
                300积分
              </li>
              <!-- <li class="r-present">(赠送300积分)</li> -->
              <!-- <li class="r-money">￥ 300</li> -->
            </ul>
          </el-radio>
          <el-radio :label="'' + 500" border size="medium">
            <ul class="r-project">
              <li class="r-integrate">
                500积分
              </li>
              <!-- <li class="r-present">(赠送500积分)</li> -->
              <!-- <li class="r-money">￥ 500</li> -->
            </ul>
          </el-radio>
          <el-radio :label="'' + 1000" border size="medium">
            <ul class="r-project">
              <li class="r-integrate">
                1000积分
              </li>
              <!-- <li class="r-present">(赠送1000积分)</li> -->
              <!-- <li class="r-money">￥ 1000</li> -->
            </ul>
          </el-radio>
          <el-radio :label=inputValue border size="medium" style="margin: 0px; margin-top: 20px; width: 360px">
            <el-input placeholder="自定义" v-model="inputValue" @input="inputVal($event)" @change="changeVal($event)">
              <i slot="suffix" style="line-height: 100px">积分</i>
            </el-input>
          </el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="可用余额(元)：" prop="money">
        <div class="p-main">
          <div class="m-money">
            <strong>{{ companyAmountList.balance }}</strong>元
            <span>(一元兑换1个积分)</span>
          </div>
          <el-radio v-model="radioValue" :label="1">{{''}}</el-radio>
        </div>
      </el-form-item>
      <!-- <el-form-item label="盈利积分：" prop="withdrawable_integral">
        <div class="p-main">
          <div class="m-integral">
            <span>{{ companyAmountList.withdrawable_integral }}</span>积分</div>
          <em @click="allWithdrawable()">全部</em>
          <el-radio v-model="radioValue" :label="2">{{''}}</el-radio>
        </div>
      </el-form-item> -->
    </el-form>
    <div class="r-btn">
      <div class="b-text" @click="handleExchange()">立即兑换</div>
    </div>
  </div>
</template>

<script>
  import {
    getCompanyAmountApi,
    exchangeResidueApi,
    exchangeWithdrawableApi
  } from '@/api/account'
  import {
    mapState
  } from 'vuex'
  export default {
    name: 'creditsExchange',
    data() {
      var validateNum = (rule, value, callback) => {
        if (!this.radioValue) {
          callback(new Error('请至少选择一项'));
        } else {
          callback();
        }
      }
      return {
        loading: false,
        companyAmountList: {
          integral: 0,
          money: '0.00',
          withdrawable_integral: 0,
        },
        creditsFormData: {
          rechargeValue: '100',
        },
        creditsRules: {
          rechargeValue: [{
            required: true,
            message: '请选择充值项目',
            trigger: 'change'
          }],
          money: [{
            required: true,
            validator: validateNum,
            trigger: 'change'
          }],
          withdrawable_integral: [{
            required: true,
            validator: validateNum,
            trigger: 'change'
          }]
        },
        inputValue: '', //其他金额
        radioValue: 1, //单选值
      }
    },
    computed: {
      ...mapState(['user_nickname', 'score', 'balance'])
    },
    watch: {
      inputValue: {
        handler(val) {
          this.creditsFormData.rechargeValue = val
        }
      }
    },
    created() {
      this.getCompanyAmountList()     
    },
    methods: {
      //数据初始化
      async getCompanyAmountList() {
        this.loading = true
        const {
          data: res
        } = await getCompanyAmountApi()
        if (res.status != 10000) {
          return this.$message.error('账号金额初始化失败')
        }
        if (res.result.length != 0) {
          this.companyAmountList = res.result
        }
        this.loading = false
      },
      allResidue() {
        this.inputValue = this.companyAmountList.balance
        this.radioValue = 1
      },
      allWithdrawable() {
        this.inputValue = this.companyAmountList.withdrawable_integral
        this.radioValue = 2
      },
      //其它金额输入的值
      inputVal() {
        var pattern = /^[1-9][0-9]*$/ //正整数的正则表达式
        // 不符合正整数时
        if (!pattern.test(this.inputValue)) {
          // input 框绑定的内容为空
          this.$message.error('输入金额为正整数')
          this.inputValue = ''
        }
        this.creditsFormData.rechargeValue = this.inputValue
      },
      changeVal(value) {
        this.creditsFormData.rechargeValue = this.inputValue
      },
      //兑换按钮
      handleExchange() {
        this.$refs.creditsRef.validate(async (valid) => {
          if (!valid) return
          if (this.radioValue == 1) {
            //使用可用余额兑换
            if (Number(this.creditsFormData.rechargeValue) <= Number(this.companyAmountList
                .balance)) {
              const {
                data: res
              } = await exchangeResidueApi({
                money: this.creditsFormData.rechargeValue
              })
              if (res.status != 10000) {
                this.$message.error(res.message)
              } else {
                this.$message.success(res.message)
                this.getCompanyAmountList()
                this.resetCreditsForm()
              }
            } else {
              this.$message.error('您选择的值或输入值不能大于可用余额')
            }
          } else if (this.radioValue == 2) {
            //盈利积分兑换
            if (Number(this.creditsFormData.rechargeValue) <= Number(this.companyAmountList
                .withdrawable_integral)) {
              const {
                data: res
              } = await exchangeWithdrawableApi({
                withdrawable_integral: this.creditsFormData.rechargeValue
              })
              if (res.status != 10000) {
                this.$message.error(res.message)
              } else {
                this.$message.success(res.message)
                this.getCompanyAmountList()
                this.resetCreditsForm()
              }
            } else {
              this.$message.error('您选择的值或输入值不能大于盈利积分')
            }
          }
        })
      },
      //重置表单
      resetCreditsForm() {
        this.inputValue = ''
        this.radioValue = ''
      }

    }
  }

</script>

<style lang="less" scoped>
  .credits-exchange {
    .r-title {
      height: 40px;
      line-height: 40px;
      font-size: 20px;
      font-weight: 500;
      margin-bottom: 20px;
      border-bottom: 1px solid #e4e7ed;
      text-align: center;
    }

    .c-orange {
      color: #ff7125;
      font-size: 20px;
      font-weight: 600;
    }

    .r-account {
      height: 80px;
      line-height: 80px;
      margin: 20px 0px;
      background: #f2f2f2;
      padding: 0px 20px;
    }

    .r-icon {
      font-size: 20px;
    }

    .c-icon {
      width: 100px;
      height: 100px;
      background: #eaca44;
      border-radius: 50%;
    }

    .p-main {
      display: flex;

      .m-money {
        min-width: 100px;
        color: #ff7125;
        margin-right: 68px;

        strong {
          font-size: 20px;
          font-weight: 600;
        }

        span {
          color: #606266;
          font-size: 12px;
          border-bottom: 1px solid #ff7125;
        }
      }

      .m-integral {
        min-width: 100px;
        color: #ff7125;

        span {
          font-size: 20px;
          font-weight: 600;
        }
      }

      em {
        font-size: 12px;
        height: 28px;
        margin: 0px 80px 0px 20px;
        border-bottom: 1px solid #ff7125;
        cursor: pointer;
      }



      .el-radio {
        display: flex;
        justify-content: center;
        align-items: center;
      }

      /deep/.el-radio__inner {
        border-radius: 2px;
      }

      /deep/.el-radio__input.is-checked .el-radio__inner::after {
        content: "";
        width: 10px;
        height: 5px;
        border: 1px solid white;
        border-top: transparent;
        border-right: transparent;
        text-align: center;
        display: block;
        position: absolute;
        top: 2px;
        left: 0px;
        vertical-align: middle;
        transform: rotate(-45deg);
        border-radius: 0px;
        background: none;
      }
    }

    .el-form {
      border-bottom: 2px solid #efefef;
    }

    .r-btn {
      position: relative;
      height: 80px;
      margin: 10px 0px;
      cursor: pointer;

      .b-text {
        width: 200px;
        height: 40px;
        line-height: 40px;
        text-align: center;
        background: #ff7125;
        color: #ffff;
        border-radius: 100px;
        font-size: 16px;
        font-weight: 600;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }

    .credits-radio {
      /deep/ .el-radio__input {
        display: none;
      }

      .el-radio {
        border: 2px solid #dcdfe6;
        text-align: center;
      }

      .is-bordered {
        border: 2px solid #dcdfe6;
      }

      .is-checked {
        border-color: #ff7125;
      }

      /deep/ .el-radio__input.is-checked+.el-radio__label {
        color: #606266;
      }

      .el-radio--medium.is-bordered {
        margin-top: 0;
        padding-top: 0;
        width: 160px;
        height: 80px;
      }
    }


    .r-project {
      height: 80px;
      font-weight: 600;
      position: relative;
    }

    .r-integrate {
      position: absolute;
      top: 20%;
      left: 50%;
      transform: translateX(-50%);
      color: #ff7125;
      font-size: 16px;
      text-align: center;
    }

    .r-money {
      color: #b0b0b0;
      margin: 20px 0px;
      font-size: 16px;
    }

    .el-input {
      width: 100%;
      height: 100%;
    }

    /deep/ .el-input__inner {
      width: 100%;
      height: 100%;
      border: 0px;
      font-size: 15px;
      font-weight: 600;
      color: #b0b0b0;
      background: #fafafa;
    }

    /deep/ .el-input__suffix {
      top: -8px;
      right: 25px;

      i {
        font-size: 14px;
        font-weight: 600;
      }
    }

    .accout {
      color: #ff7125;
      font-size: 16px;
      font-weight: 600;
    }
  }

</style>
