<template>
  <div class="my-balance">
    <div class="b-title">佣金管理</div>
    <el-row class="b-money" v-loading="loading">
      <el-col :span="8" class="m-usable">
        账户可提现佣金：
        <p>{{ balance }}</p>
        <el-button type="danger" size="mini" class="m-btn" @click="dialogVisible = true">提现</el-button>
      </el-col>
      <el-col :span="8">
        账户累计佣金：
        <p>{{ total_balance }}</p>
      </el-col>
    </el-row>
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="佣金流水" name="first">
        <CommissionStream ref="Stream" />
      </el-tab-pane>
      <el-tab-pane label="提现记录" name="second">
        <WithdrawalRecord ref="Record" />
      </el-tab-pane>
    </el-tabs>
    <el-dialog title="佣金提现申请" :visible="dialogVisible" width="25%" :before-close="beforeCloseDialog">
      <el-form ref="dialogRef" :model="formData">
        <el-form-item label="提现金额：" label-width="100" prop="money" :rules="[
      { required: true, message: '提现金额不能为空'}
    ]">
          <el-input-number v-model="formData.money" placeholder="请输入提现金额" :precision="2" :min="1"
            :max="parseFloat(balance)" size="medium">
          </el-input-number>
        </el-form-item>
      </el-form>
      <span slot="footer">
        <el-button type="danger" @click="sumbit('dialogRef')" size="medium">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
  import Pagination from '@/components/Pagination/index.vue'
  import CommissionStream from './components/CommissionStream.vue'
  import WithdrawalRecord from './components/WithdrawalRecord.vue'
  import {
    getUserBalanceApi,
    withdrawalApi
  } from '@/api/commission'
  export default {
    components: {
      Pagination,
      CommissionStream,
      WithdrawalRecord
    },
    data() {
      return {
        balance: '',
        total_balance: '',
        loading: false,
        formData: {
          money: ''
        },
        dialogVisible: false,
        activeName: 'first',
        recordList: [],
        record: {
          page: 1,
          limit: 10,
          count: 0
        }
      }
    },
    created() {
      this.getUserBalance()
    },
    methods: {
      // 佣金账户
      async getUserBalance() {
        this.loading = true
        const {
          data: res
        } = await getUserBalanceApi()
        this.balance = res.result.balance
        this.total_balance = res.result.total_balance
        this.loading = false
      },
      beforeCloseDialog() {
        this.dialogVisible = false
        this.$refs.dialogRef.resetFields()
      },
      //   提现申请
      sumbit(formName) {
        this.$refs[formName].validate(async (valid) => {
          if (!valid) return;
          const {
            data: res
          } = await withdrawalApi({
            money: this.formData.money
          })
          if (res.status !== 10000) {
            return this.$message.error(res.message)
          }
          this.$message.success('提交审核成功')
          this.getUserBalance()
          this.beforeCloseDialog()
        });
      },
      handleClick(tab) {
        if (tab.index == 0) {
          this.$refs.Stream.getCommissionStream()
        } else if (tab.index == 1) {
          this.$refs.Record.getWithdrawalRecord()
        }
      }
    }
  }

</script>

<style lang="less" scoped>
  p {
    color: #ff7125 !important;
  }

  .my-balance {
    .b-title {
      height: 40px;
      line-height: 40px;
      font-size: 20px;
      font-weight: 500;
      margin-bottom: 20px;
      border-bottom: 1px solid #e4e7ed;
      text-align: center;
    }

    .b-money {
      height: 70px;
      margin: 20px 0px;
      font-size: 14px;
      color: #969696;

      .m-usable {
        position: relative;

        .m-btn {
          position: absolute;
          top: 50%;
          right: 30%;
          font-size: 16px;
        }

        a {
          text-decoration: none;
        }
      }

      p {
        margin-top: 10px;
        color: #2b1414;
        font-size: 32px;
      }
    }

    .d-bd {
      text-align: center;
      color: #666;
      font-size: 14px;
      position: relative;
      // min-height: 500px;

      .b-nobill {
        position: absolute;
        top: 30%;
        left: 50%;
        transform: translateX(-50%);
        width: 224px;
        height: 142px;
        margin-bottom: 20px;

        img {
          width: 224px;
          height: 142px;
        }
      }

      .b-table {
        border: 1px solid #e1251b;
        margin-top: 10px;

        .t-hd {
          height: 40px;
          background: #f5f5f5;
        }
      }
    }

    .pagination {
      margin-top: 20px;
      text-align: right;
    }
  }

</style>
