import request from '@/libs/request'

/**
 * 佣金账户数据
 */
export function getUserBalanceApi() {
  return request({
    url: `/commission/user/balance`,
    method: 'get'
  })
}

/**
 * 佣金提现申请
 */
export function withdrawalApi(data) {
  return request({
    url: `/commission/user/withdrawal`,
    method: 'post',
    data
  })
}

/**
 * 佣金流水记录
 */
export function commissionStreamApi(params) {
  return request({
    url: `/commission/stream`,
    method: 'get',
    params
  })
}

/**
 * 提现记录
 */
export function withdrawalRecordApi(params) {
  return request({
    url: `/commission/user/withdrawal_record`,
    method: 'get',
    params
  })
}
