<template>
  <div>
    <el-card>
      <el-table :data="streamList"
        :header-cell-style="{'text-align': 'center',background: '#f2f2f2',color: '#838a96',border: 'none'}"
        :cell-style="{ 'text-align': 'center', color: '#000' }" style="width: 100%" v-loading="loading">
        <el-table-column prop="id" label="ID"></el-table-column>
        <el-table-column prop="s_type" label="流水性质">
          <template slot-scope="scope">
            {{ scope.row.s_type == 0 ? '增加' : '减少' }}
          </template>
        </el-table-column>
        <el-table-column prop="money" label="金额"></el-table-column>
        <el-table-column prop="type" label="业务类型">
          <template slot-scope="scope">
            {{ scope.row.type | typeFilter }}
          </template>
        </el-table-column>
        <el-table-column prop="add_time" label="时间">
          <template slot-scope="scope">
            {{ scope.row.add_time | formatDate }}
          </template>
        </el-table-column>
      </el-table>
      <el-row class="pagination">
        <Pagination :page.sync="page" :limit.sync="limit" :total="count" @getList="getCommissionStream" />
      </el-row>
    </el-card>
  </div>
</template>

<script>
  import Pagination from '@/components/Pagination/index.vue'
  import {
    commissionStreamApi
  } from '@/api/commission'
  export default {
    components: {
      Pagination
    },
    data() {
      return {
        streamList: [],
        page: 1,
        limit: 10,
        count: 0,
        loading: false
      }
    },
    created() {
      this.getCommissionStream()
    },
    filters: {
      typeFilter(val) {
        switch (val) {
          case 0:
            return '导入数据';
            break;
          case 1:
            return '大保活动报名';
            break;
          case 2:
            return '培训报考';
            break;
          case 3:
            return '购买数据';
            break;
          case 10:
            return '提现';
            break;
        }
      }
    },
    methods: {
      // 佣金流水记录
      async getCommissionStream() {
        this.loading = true
        const {
          data: res
        } = await commissionStreamApi({
          page: this.page,
          limit: this.limit
        })
        if (res.status != 10000) {
          return this.$message.error(res.message)
        }
        this.streamList = res.result.list
        this.count = res.result.count
        this.loading = false
      }
    }
  }

</script>

<style lang="less" scoped>
  .pagination {
    margin-top: 20px;
    text-align: right;
  }

</style>
