<template>
  <div>
    <el-card>
      <el-table :data="recordList"
        :header-cell-style="{'text-align': 'center',background: '#f2f2f2',color: '#838a96',border: 'none'}"
        :cell-style="{ 'text-align': 'center', color: '#000' }" style="width: 100%" v-loading="loading">
        <el-table-column prop="id" label="ID" width="70"></el-table-column>
        <el-table-column prop="money" label="金额" width="90"></el-table-column>
        <el-table-column prop="status" label="申请状态" width="100">
          <template slot-scope="scope">
            {{ scope.row.status | statusFilter }}
          </template>
        </el-table-column>
        <el-table-column prop="add_time" label="申请发起时间" width="150">
          <template slot-scope="scope">
            {{ scope.row.add_time | formatDate }}
          </template>
        </el-table-column>
        <el-table-column prop="verify_time" label="审计完成时间" width="150">
          <template slot-scope="scope">
            {{ scope.row.verify_time | formatDate }}
          </template>
        </el-table-column>
        <el-table-column prop="transfer_time" label="转账完成时间" width="150">
          <template slot-scope="scope">
            {{ scope.row.transfer_time | formatDate }}
          </template>
        </el-table-column>
        <el-table-column prop="verify_msg" label="审核人员留言"></el-table-column>
      </el-table>
      <el-row class="pagination">
        <Pagination :page.sync="page" :limit.sync="limit" :total="count" @getList="getWithdrawalRecord" />
      </el-row>
    </el-card>
  </div>
</template>

<script>
  import Pagination from '@/components/Pagination/index.vue'
  import {
    withdrawalRecordApi
  } from '@/api/commission'
  export default {
    components: {
      Pagination
    },
    data() {
      return {
        recordList: [],
        page: 1,
        limit: 10,
        count: 0,
        loading: false
      }
    },
    created() {
      this.getWithdrawalRecord()
    },
    filters: {
      statusFilter(val) {
        switch (val) {
          case 0:
            return '申请发起';
            break;
          case 1:
            return '审计通过';
            break;
          case 2:
            return '财务转账';
            break;
          case 3:
            return '审计不通过';
            break;
        }
      }
    },
    methods: {
      // 提现记录
      async getWithdrawalRecord() {
        this.loading = true
        const {
          data: res
        } = await withdrawalRecordApi({
          page: this.page,
          limit: this.limit
        })
        if (res.status != 10000) {
          return this.$message.error(res.message)
        }
        this.recordList = res.result.list
        this.count = res.result.count
        this.loading = false
      },
    }
  }

</script>

<style lang="less" scoped>
  .pagination {
    margin-top: 20px;
    text-align: right;
  }

</style>
