<template>
  <div class="enroll-details">
    <div class="c-title">人力资源输入</div>
    <el-card v-loading="loading">
      <!-- 表单数据展示 -->
      <!-- 表单 -->
      <div class="listdiv">
        <div class="userlist" v-if="addList.length!=0" v-for="(item,index) in addList"
          :key="index">
          <div class="l-form">
            <div class="listtop"></div>
            <el-form class="demo-form-inline" size="small" label-width="100px">
              <el-form-item label="姓名：">
                {{ item.user_truename }}
              </el-form-item>
              <el-form-item label="手机号码：">
                {{ item.user_phone }}
              </el-form-item>
              <el-form-item label="身份证号码：">
                {{ item.user_idcard }}
              </el-form-item>
              <el-form-item label="学历:">
                {{ item.education_level | educationFormat}}
              </el-form-item>
            </el-form>
          </div>
        </div>
        <div class="userlist">
          <div class="l-form" v-for="(item,index) in list" :key="index" v-loading="oneloading"
            element-loading-text="等待录入" element-loading-spinner="el-icon-loading"
            element-loading-background="rgba(0, 0, 0, 0.8)" :ref="`lond${index}`">
            <!-- <div class="listtop" @click="deleteForm(index)"><i class="el-icon-close"></i>&nbsp;&nbsp;&nbsp;</div> -->
            <div class="listtop"></div>
            <el-form class="demo-form-inline" :model="item" :ref="`refForm${index}`" :label-position="labelPosition"
              size="small" label-width="110px" :rules="rules">
              <el-form-item label="姓名：" prop="user_truename">
                <el-input v-model="item.user_truename" placeholder="请输入姓名" suffix-icon="el-icon-user"></el-input>
              </el-form-item>
              <el-form-item label="手机号码：" prop="user_phone">
                <el-input v-model="item.user_phone" placeholder="请输入手机号码" maxlength="11" suffix-icon="el-icon-phone">
                </el-input>
              </el-form-item>
              <el-form-item label="身份证号码：" prop="user_idcard">
                <el-input v-model="item.user_idcard" placeholder="请输入身份证号码" maxlength="18"
                  suffix-icon="el-icon-tickets">
                </el-input>
              </el-form-item>
              <el-form-item label="学历:" prop="education_level">
                <el-select v-model="item.education_level" placeholder="请选择学历" size="small" filterable
                  style="width: 100%">
                  <el-option v-for="(item,index) in educationList" :key="index" :label="item" :value="index">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-form>
          </div>
          <el-form class="d-form-inline" ref="refForma" :model="info" size="small" inline label-position="right">
            <el-form-item>
              <el-button type="primary" @click="addTo()" size="small">确定录入</el-button>
            </el-form-item>
            <!-- <el-form-item style="margin-left:5%;">
          <el-upload action="" :multiple="false" :show-file-list="false" :on-change="onChange" :auto-upload="false">
            <el-button type="primary">读取excel</el-button>
          </el-upload>
        </el-form-item> -->
            <!-- <el-form-item>
          <el-tooltip class="item" effect="dark" content="批量录入excel文件格式下载" placement="top-start">
            <a class="el-button el-tooltip item el-button--info el-button--small is-plain" target=“_blank”
              href="https://serfoo.com/storage/school_admin/特许经营用户批量录入格式.xlsx">excel格式下载</a>
          </el-tooltip>
        </el-form-item> -->
          </el-form>
        </div>

        <!-- <div class="useradd" @click="submitForm()">
          <i class="el-icon-plus"></i>
        </div> -->
      </div>
    </el-card>


  </div>
</template>

<script>
  import {
    userAddApi,
    getEducationInfoAPI
  } from '@/api/franchise'
  var xlsx = require('xlsx')
  /* 读取文件 */
  export const readFile = (file) => {
    return new Promise(resolve => {
      let reader = new FileReader()
      reader.readAsBinaryString(file)
      reader.onload = ev => {
        resolve(ev.target.result)
      }
    })
  }
  export default {
    name: "enrollDetails",
    data() {
      return {
        info: {
          tid: '',
          crttid: '',
          cardCode: ''
        },
        educationLevelMap: {
          1: '是',
          0: '否'
        },
        labelPosition: 'right',
        educationList: [], // 文化程度列表
        list: [],
        addList: [],
        loading: false,
        oneloading: false,
        rules: {
          user_truename: [{
              required: true,
              message: '请输入姓名',
              trigger: 'blur'
            },
            {
              min: 2,
              max: 10,
              message: '长度在 2 到 10 个字符',
              trigger: 'blur'
            },
            {
              required: true,
              pattern: /^[\u4e00-\u9fa5_a-zA-Z0-9.·-]+$/,
              message: '姓名不支持特殊字符',
              trigger: 'blur'
            }
          ],
          user_phone: [{
              required: true,
              message: "请输入手机号码",
              trigger: "blur"
            },
            {
              required: true,
              pattern: /^[1][3,4,5,6,7,8,9][0-9]{9}$/,
              message: '请输入正确的手机号码',
              trigger: 'blur'
            }
          ],
          user_idcard: [{
              required: true,
              message: '请输入身份证号',
              trigger: 'blur'
            },
            {
              min: 15,
              max: 18,
              message: '正确的身份证号码长度应该为 15 位到 18 位',
              trigger: 'blur'
            },
            {
              required: true,
              pattern: /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/,
              message: '请输入正确的身份证号码',
              trigger: 'blur'
            }
          ],
          education_level: [{
              required: true,
              message: "请选择学历",
              trigger: "blur"
            },
            {
              type: "enum",
              enum: ['1', '2', '3', '4', '5', '6'],
              message: '学历参数错误',
              trigger: 'blur'
            }
          ]
        }
      }
    },
    created() {
      this.submitForm()
      this.getEducationInfo()
    },
    methods: {
      addTo() { // 数据提交
        this.oneloading = true;
        let refForma = this.$refs['refForma'];
        refForma.validate(res => {
          if (!res) {
            this.oneloading = false;
            return false;
          }
        })
        var Arr = [].concat(this.list);
        for (let index = Arr.length - 1; index >= 0; index--) {
          let key = this.listsplice(Arr[index]);
          let valiForm = this.$refs['refForm' + key][0]; // 表单验证
          valiForm.clearValidate()
          valiForm.validate(res => {
            if (!res) {
              this.oneloading = false;
              this.$alert(Arr[index].user_truename + ' 信息存在格式有误，请确认该用户信息！', '信息格式有误', {
                confirmButtonText: '确定',
                callback: action => {
                  return false;
                }
              });
              return false;
            }
          })
          if (!this.oneloading) return false;
        }
        var that = this;
        setTimeout(function () {
          that.addTwo();
        }, 1000);
      },
      async addTwo() {
        var Arr = [].concat(this.list);
        for (let index = 0; index <= Arr.length - 1; index++) {
          let key = this.listsplice(Arr[index]);
          this.$refs['lond' + key][0].children[2].children[0].children[1].innerHTML = '正在录入'
          const {
            data: res
          } = await userAddApi(
            Arr[index]
          ); // 提交至服务器
          if (res.status == 10000) { // 录入成功

            this.$refs['lond' + key][0].children[2].children[0].children[1].innerHTML = '录入成功'
            this.addList.push(Arr[index])
            this.list.splice(key, 1); // 删除当前学员
            this.submitForm()
          } else if (res.status == 99008) { // 当前学员数据没有通过实名认证处理
            let valiForm = this.$refs['refForm' + key][0]; // 表单错误提示
            valiForm.fields[2].validateState = 'error'
            valiForm.fields[2].validateMessage = res.message;
          } else if (res.status == 99009) {
            let valiForm = this.$refs['refForm' + key][0]; // 表单错误提示
            valiForm.fields[1].validateState = 'error'
            valiForm.fields[1].validateMessage = res.message;
          } else {
            this.$message.error(res.message);
            this.oneloading = false;
            return false;
          }
        }
        this.oneloading = false;
      },
      listsplice(student) { // 返回指定学员在 this.list 的位置
        for (let index in this.list) {
          if (this.list[index].user_truename == student.user_truename && this.list[index].user_phone == student
            .user_phone && this.list[index].user_idcard == student.user_idcard)
            return index;
        }
      },
      //   async onChange(file) { // 批量excel读取
      //     this.loading = true
      //     let dataBinary = await readFile(file.raw)
      //     let workBook = xlsx.read(dataBinary, {
      //       type: 'binary',
      //       cellDates: true
      //     })
      //     let workSheet = workBook.Sheets[workBook.SheetNames[0]]
      //     const data = xlsx.utils.sheet_to_json(workSheet)
      //     if (this.list.length <= 1) {
      //       this.list = new Array()
      //     }
      //     for (let index in data) { // 表单验证
      //       if (data[index].hasOwnProperty("姓名") && data[index].hasOwnProperty("手机号码") && data[index]
      //         .hasOwnProperty(
      //           "身份证号") && data[index].hasOwnProperty("学历")) {
      //         let user_truename = data[index]["姓名"].toString();
      //         let user_phone = data[index]["手机号码"].toString();
      //         let user_idcard = data[index]["身份证号"].toString();
      //         let education_level = data[index]["学历"].toString();
      //         this.list.push({
      //           user_truename: user_truename,
      //           user_phone: user_phone,
      //           user_idcard: user_idcard,
      //           education_level: education_level
      //         })
      //       } else {
      //         return this.$message.error("excel数据格式不对，不能正常读取，请点击 excel格式下载按钮，下载标准格式")
      //       }
      //     }
      //     this.$nextTick(() => {
      //       this.loading = false
      //       let refForma = this.$refs['refForma'];
      //       refForma.validate(res => {
      //         if (!res) {}
      //       });
      //       var Arr = [].concat(this.list);
      //       for (let index = Arr.length - 1; index >= 0; index--) {
      //         let key = this.listsplice(Arr[index]);
      //         let valiForm = this.$refs['refForm' + key][0]; // 表单验证
      //         valiForm.clearValidate()
      //         valiForm.validate(res => {
      //           if (!res) {}
      //         })
      //       }
      //     })
      //   },
      submitForm() { // 添加
        let verification = true;
        for (let index in this.list) { // 表单验证
          let valiForm = this.$refs['refForm' + index][0];
          valiForm.validate(res => {
            if (!res) verification = false;
          })
        }
        if (verification) this.list.push({
          user_truename: '',
          user_phone: '',
          user_idcard: '',
          education_level: ''
        });
      },
      //   deleteForm(index) { //  删除
      //     this.list.splice(index, 1);
      //   },
      async getEducationInfo() { // 获取文化程度配置参数
        this.loading = true
        const {
          data: res
        } = await getEducationInfoAPI()
        this.loading = false
        if (res.status !== 10000) {
          return this.$message.error("服务器繁忙请稍后在试")
        }
        this.educationList = res.result
      }
    }
  }

</script>

<style lang="less" scoped>
  .c-title {
    height: 40px;
    line-height: 40px;
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 20px;
    border-bottom: 1px solid #e4e7ed;
    text-align: center;
  }

  .enroll-details {
    text-align: left;
    line-height: normal;
  }

  .listdiv {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .userlist {
    width: 48%;
  }

  .l-form {
    height: 260px;
    border: 1px solid #e5e5e5;
    border-radius: 3px;
    margin: 11px 11px;
  }

  .listtop {
    width: 100%;
    height: 36px;
    background: #e5e5e5;
    margin-bottom: 20px;
    text-align: right;
    line-height: 36px;
    cursor: pointer;
    color: #a7a7a7;
  }

  .demo-form-inline {
    padding: 0 20px;
  }

  .d-form-inline {
    display: flex;
    padding: 0 20px;
    margin-top: 20px;
  }

  .useradd {
    width: 45%;
    height: 260px;
    border: 1px solid #e5e5e5;
    border-radius: 5px;
    margin: 11px 11px;
    line-height: 250px;
    text-align: center;
    font-size: 30px;
    cursor: pointer;
    color: #e5e5e5;
  }

</style>
